import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';

// Pages
let HomePage = React.lazy(() => import("./pages/HomePage"));
let ProfissionaisSaude = React.lazy(() => import("./pages/ProfissionaisSaude"));
let ClinicaInternacao = React.lazy(() => import("./pages/ClinicaInternacao"));
let PessoaFisica = React.lazy(() => import("./pages/PessoaFisica"));
let Instituicoes = React.lazy(() => import("./pages/Instituicoes"));
let Nowah = React.lazy(() => import("./pages/Nowah"));
let FormularioProfissionaisSaude = React.lazy(() => import("./pages/FormularioProfissionaisSaude"));
let FormularioClinicas = React.lazy(() => import("./pages/FormularioClinicas"));
let FormularioPessoaFisica = React.lazy(() => import("./pages/FormularioPessoaFisica"));

const App = () => {
  const [ wait, setWait ] = useState(true);

  useEffect(() => {
    setTimeout(() => setWait(false), 1000)
  }, []);

  return (
    !wait && 
    <React.Suspense fallback={<div></div>}>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/profissionais-saude" element={<ProfissionaisSaude />} />
      <Route path="/clinica-internacao" element={<ClinicaInternacao/>} />
      <Route path="/pessoa-fisica" element={<PessoaFisica />} />
      <Route path="/instituicoes" element={<Instituicoes />} />
      <Route path="/funcoes-nowah" element={<Nowah />} />
      <Route path="/cadastro-profissionais-saude" element={<FormularioProfissionaisSaude/>} />
      <Route path="/cadastro-clinica-internacao" element={<FormularioClinicas/>} />
      <Route path='/cadastro-pessoa-fisica' element={<FormularioPessoaFisica/>} />
    </Routes>
    </React.Suspense>

  )}

  

export default App;
